import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const ChumatskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "chumatski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Чумацькі пісні"
        description="Чумацькі пісні — народні пісні про життя, побут, пригоди й переживання чумаків."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Чумацькі пісні
          </h1>
          <p className="typo-body mt-4 max-w-xl">
            Чумацькі пісні — народні пісні про життя, побут, пригоди й переживання чумаків.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список чумацьких пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру чумацької пісні
        </h2>
        <p className="typo-body mt-4">
          Чумацький промисел в Україні відомий з  XV ст. а його розквіт припадає на XVІІІ — поч. XІX ст. Чумаки торгували
          переважно рибою та сіллю, яку привозили на волах з Дону і з берегів Чорного й Азовського морів.
        </p>
        <p className="typo-body mt-4">
          Довгий шлях, невлаштованість побуту, небезпека ворожих нападів, хвороби і смерть на чужині — основні мотиви чумацьких пісень. В них  показані й причини, які змушували селян вдаватись до чумакування:
        </p>
        <p className="typo-body italic mt-4">
          Ой тим же я чумакую,
          <br />
          Що так мені лучче жити:
          <br />
          На панщину не ходити,
          <br />
          Подушного не платити…
        </p>
        <p className="typo-body mt-4">
          Злидні, — “ані солі одробинки, ані хліба окрошинки” гнали з дому, змушували шукати долі — “хвортуни” в далекому краю:
        </p>
        <p className="typo-body italic mt-4">
          Ой хвортуно, ти, небого,
          <br />
          Послужи мені немного, —
          <br />
          служила ж у хазяйстві,
          <br />
          та й служила у бурлацтві,
          <br />
          ще й послужи у чумацтві.
        </p>
        <p className="typo-body mt-4">
          Але нерідко надії не справджувались і повертався чумак з Дону додому тільки з батіжком у руках, «за плечима
          торбина, ще й латана свитина — дочумакувався!» Серед лихих пригод — хвороб, каліцтва — пісні з осудом нази­вають
          і пияцтво, яке призводило до гіркого фіналу: «не знать за що пропив вози, пропив ярма ще й занози, а сам ходиш
          по дорозі, проливавши гіркі сльози».
        </p>
        <p className="typo-body mt-4">
          Чумацьким промислом займалися й козаки. Відомо, що запорожці супроводжували чумацькі валки, захищаючи їх від
          нападів татар, залишали чума­ків у своїх поселеннях на зимівлю. Деякі мотиви чумацьких пісень перегу­куються з
          козацькими; окремі образи, порівняння, метафори повторюються в козацьких, чумацьких, а далі й солдатських
          піснях:
        </p>
        <p className="typo-body italic mt-4">
          Та по дорогах річки течуть,
          <br />
          Понад шляхом маки цвітуть.
          <br />
          То ж не маки — то чумаки
          <br />
          Везуть рибу — все судаки.
        </p>
        <p className="typo-body mt-4">
          Образне порівняння героїв пісні з розсипами червоних маків понад шляхом більше відповідає реаліям козацького
          побуту,— козаки носили шапки з червоним верхом; в козацьких піснях не раз бачимо цей образ, звідти він перейшов
          і до інших циклів.
        </p>
        <p className="typo-body mt-4">
          Чумацькі валки звичайно споряджалися напровесні; можливо, саме цим пояснюються аналогії або й тотожність
          заспівів веснянок і чумацьких пісень. Порівняємо:
        </p>
        <p className="typo-body mt-4">
          У веснянці:
        </p>
        <p className="typo-body italic mt-4">
          А вже весна, а вже красна,
          <br />
          Із стріх вода крапле.
          <br />
          Молодому козакові
          <br />
          Мандрівочка пахне.
        </p>
        <p className="typo-body mt-4">
          В чумацькій пісні:
        </p>
        <p className="typo-body italic mt-4">
          Весна красна наступає,
          <br />
          Із стріх вода капле.
          <br />
          Молодому чумакові
          <br />
          Шлях-дорога пахне.
        </p>
        <p className="typo-body mt-4">
          До далекої подорожі готувались заздалегідь, про що з епічною послідовістю й детальним поясненням розповідається
          в чумацьких піснях, в яких наче вчуваються повільні ритми ходи чумацької валки:
        </p>
        <p className="typo-body italic mt-4">
          Задумали чумаки в дорогу,
          <br />
          Покупили собі нові вози,
          <br />
          Поробили ярма кленові,
          <br />
          Поробили занози дубові,
          <br />
          Покупили воли половії,
          <br />
          Покупили да й попаровали,
          <br />
          Понаровали да й повиїжджали...
        </p>
        <p className="typo-body mt-4">
          Чумацькі пісні надихали поетів, композиторів, художників. Вірші Т. Шевченка «Ой не п’ються пива-меди». «Неначе
          степом чумаки», «У неділю не гуляла» навіяні образами чумацьких пісень. Їхній вплив відчувається в оповіданні
          М. Вовчка «Чумак», в народнопісенній опері С. Руданського «Чумак — український дивоспів», у п’єсі
          І. Карпенка-Карого «Чумак».
        </p>
      </div>
    </>
  );
};

export default ChumatskiPisniPage;
